<template>
  <div class="partner" id="partner">
    <content1 id="hpartner" />
    <content2 id="srvcof" />
    <content3 id="bntsprtnr" />
    <content4 id="hwtbprtnr" />
    <content5 id="cntctus" />
  </div>
</template>

<script>
export default {
  name: 'partner',
  components: {
    'content1': () => import('./Content1.vue'),
    'content2': () => import('./Content2.vue'),
    'content3': () => import('./Content3.vue'),
    'content4': () => import('./Content4.vue'),
    'content5': () => import('./Content5.vue')
  },
  mounted() {
    var VueScrollTo = require('vue-scrollto');
    var element = location.hash

    if (location.hash) {
      this.$nextTick(() => {
        setTimeout(function() {
          VueScrollTo.scrollTo(element)
        }, 100)
      })
    }
  }
}
</script>

<style scoped lang='scss'></style>
